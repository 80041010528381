<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start>
      <v-flex xs12 align-self-center style="margin-all: 0px;">
        <v-layout wrap justify-center>
          <v-flex
            xs12
            lg10
            :style="
              $vuetify.breakpoint.name == 'xs'
                ? 'margin-top:0%'
                : 'padding-top:0%'
            "
            align-self-center
          >
            <v-layout wrap justify-center>
              <v-flex xs12 align-self-center pt-6>
                <v-card
                  tile
                  flat  
                >
                  <v-layout wrap justify-center py-6>
                    <v-flex xs12 md10 lg8 align-self-center pa-4 py-8>
                      <v-card tile>
                        <v-layout wrap justify-center pa-4>
                          <v-flex xs12 pt-2 text-center align-self-start>
                            <span
                              style="font-family: poppinsbold; font-size: 24px"
                              >Share Via</span
                            >
                          </v-flex>
                          <v-flex
                            xs2
                            sm2
                            text-left
                            py-4
                            px-2
                            px-sm-4
                            v-for="(item, i) in socialMedias"
                            :key="i"
                          >
                            <ShareNetwork
                              :network="item.network"
                              :url="
                                'https://amphibians.wti.org.in' +
                                  $route.fullPath
                              "
                              :title="
                                item.network == 'messenger' ||
                                item.network == 'linkedin'
                                  ? ''
                                  : ''
                              "
                            >
                              <v-img
                                :width="item.size"
                                contain
                                :src="getImgUrl(item.image)"
                              ></v-img>
                            </ShareNetwork>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <Winners />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Winners from "./Winners";
export default {
  props: ["banner", "doScroll"],
  components: {
    Winners,
  },
  watch: {
    doScroll() {
      if (this.doScroll) {
        this.scroll("ruleId");
      }
    },
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      rulesInfo: [],
      ruleselected: null,
      cardData: [
        {
          title: "Click a Frog",
          image: require("./../../assets/images/CLICK.jpg"),
          // image: require("./../../assets/images/f1.jpg"),
          desc:
            "For all the photographers out there, click a frog in its natural habitat, and share the picture with us. Top three entries selected by our jury will win exciting prizes!",
        },
        // {
        //   title: "Paint a Frog",
        //   image: require("./../../assets/images/DRAW.jpg"),
        //   // image: require("./../../assets/images/f2.jpg"),
        //   desc:
        //     "Love to paint, but haven’t picked up your brush for a while? Why not paint a frog and share the painting. Our jury will select the top three artworks for the awesome prizes!",
        // },
        // {
        //   title: "Be a Frog",
        //   image: require("./../../assets/images/PAINT.jpg"),
        //   // image: require("./../../assets/images/f3.jpg"),
        //   desc:
        //     "Dare to be a frog? Well, the theme is to paint your face as a frog. The three most creative, whacky, original submissions are up for the lovely prizes! Surprise us!",
        // },
      ],

      socialMedias: [
        {
          network: "whatsapp",
          title: "Whatsapp",
          icon: "mdi-whatsapp",
          image: "whatsapp.png",
          size: "50px",
        },
        // { network: "Messenger", icon: "mdi-facebook-messenger" },
        {
          network: "facebook",
          title: " Facebook",
          icon: "mdi-facebook",
          image: "fb.png",
          size: "50px",
        },
        {
          network: "twitter",
          title: " Twitter",
          icon: "mdi-twitter",
          image: "twitter.png",
          size: "50px",
        },
        {
          network: "email",
          title: "Gmail ",
          icon: "mdi-email",
          image: "gmail.png",
          size: "58px",
        },
        {
          network: "linkedin",
          title: "Linkedin ",
          icon: "mdi-linkedin",
          image: "linkedin.png",
          size: "50px",
        },
        // { network: "telegram",title:"Telegram ",  icon: "mdi-telegram" ,size:"50px"},
      ],
      shareDialog: false,
      isClickedPrize: false,
    };
  },
  methods: {
    scroll(id) {
      id = "ruleId";
      console.log("Scrlled-=", id);
      document.getElementById("ruleId").scrollIntoView({
        behavior: "smooth",
      });
      this.doScroll = false;
    },

    // scroll(id) {
    //   console.log("Scrlled-=",id)
    //   document.getElementById(id).scrollIntoView({
    //     behavior: "smooth",
    //   });
    //   this.doScroll = false;
    // },

    getImgUrl(pic) {
      return require("../../assets/images/social/" + pic);
    },
    clickedOn() {
      // this.$emit("bstepper", {
      //   isClicked: true,
      // });

      document.getElementById("subform").scrollIntoView({
        behavior: "smooth",
      });
    },

    clickedOn2() {
      // this.$emit("stepperPrize", {
      //   isClickedPrize: true,
      // });

      document.getElementById("prizeCol").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.texth {
  line-height: 1.5em;
  min-height: 9.5em;
  float: left;
  width: 100%;
}
.hovCls {
  color: #005f32;
  border-bottom: 3px solid #005f32;
}

.hovCls:hover {
  color: blue;
  border-bottom: 3px solid blue;
}
</style>
