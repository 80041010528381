<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start>
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-center>
          <v-flex xs12 lg10 align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12 sm10 align-self-center pa-2 py-12>
                <v-layout wrap justify-center>
                  <v-flex xs12 pa-4 align-self-center>
                    <span
                      class="poppinssemibold"
                      style="font-size:26px;color:#005f32"
                    >
                      THE PHOTO CONTEST AND QUIZ SWEEPSTAKE RESULTS ARE OUT!!!
                    </span>
                  </v-flex>
                  <v-flex align-self-center xs12 pa-4>
                    <span
                      class="poppinsregular"
                      style="font-size:18px;color:#000"
                    >
                      We got some wonderful entries for ‘Click A Frog’. We are
                      also delighted with the participation received for the
                      Frog Quiz. It was an absolute pleasure to host
                      #FrogsForPlanet contest and we hope you enjoyed as much.
                      <br />
                      <br />
                      Thank you to all the participants!
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm11 align-self-center pa-2 py-6>
                <v-layout wrap>
                  <v-flex xs12 px-2 py-1 align-self-center>
                    <span
                      class="poppinssemibold"
                      style="font-size:26px;color:#005f32"
                    >
                      GRAND PRIZE WINNER
                    </span>
                  </v-flex>
                  <v-flex align-self-center xs12 px-2 py-1>
                    <span
                      class="poppinssemibold"
                      style="font-size:23px;color:#000"
                    >
                      Mayuresh Hendre
                    </span>
                  </v-flex>
                  <v-flex align-self-center xs12 px-2 py-1>
                    <v-card tile outlined>
                      <v-layout wrap pa-4>
                        <v-flex xs12 align-self-center pa-2>
                          <i>
                            <span
                              class="poppinssemibold"
                              style="font-size:18px;color:#000"
                            >
                              “While searching for amphibians and reptiles on a
                              herping trip in Valparai in the south western
                              ghats, I stumbled upon this beautiful frog right
                              next to a small fast flowing stream in the forest.
                              This frog was just little bigger than a thumb.
                              After waiting patiently and giving the frog some
                              time, I managed to go close with my wide angle
                              lens and create an image with its habitat. A
                              Golden Backed Frog, along a stream, in it's
                              beautiful habitat in Valparai, Anaimalai Tiger
                              Reserve, Tamil Nadu.”
                            </span>
                          </i>
                        </v-flex>
                        <v-flex xs12 align-self-center pa-2 py-4>
                          <span
                            class="poppinsregular"
                            style="font-size:18px;color:#000"
                          >
                            <b>We loved the picture so much :</b> Mayuresh
                            beautifully captures the golden backed frog in its
                            natural habitat. With the flowing waters frozen in
                            time in the background, the frame shows the species
                            attachment to its habitat giving it a dynamic
                            appeal.
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>

                  <v-flex align-self-center xs12 pa-4>
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm10 align-self-center>
                        <a
                          target="_blank"
                          :href="require('./../../assets/images/mayu.png')"
                        >
                          <v-img
                            contain
                            :src="require('./../../assets/images/mayu.png')"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#005f32"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 sm6 align-self-center pa-6>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 sm11 align-self-center pa-2 py-6>
                <v-layout wrap>
                  <v-flex xs12 px-2 py-1 align-self-center>
                    <span
                      class="poppinssemibold"
                      style="font-size:26px;color:#005f32"
                    >
                      2<span style="font-size:12px; ">ND</span> PLACE WINNER
                    </span>
                  </v-flex>
                  <v-flex align-self-center xs12 px-2 py-1>
                    <span
                      class="poppinssemibold"
                      style="font-size:23px;color:#000"
                    >
                      Samiran Halder
                    </span>
                  </v-flex>
                  <v-flex align-self-center xs12 px-2 py-1>
                    <v-card tile outlined>
                      <v-layout wrap pa-4>
                        <v-flex xs12 align-self-center pa-2>
                          <i>
                            <span
                              class="poppinssemibold"
                              style="font-size:18px;color:#000"
                            >
                              “Clicked a Polypedates maculatus in Belun Eco
                              Village, Burdwan.”
                            </span>
                          </i>
                        </v-flex>
                        <v-flex xs12 align-self-center pa-2 py-4>
                          <span
                            class="poppinsregular"
                            style="font-size:18px;color:#000"
                          >
                            <b>It was a beautiful picture :</b> A perfect shot
                            showing how we can coexist with species like frogs.
                            All they need is a small, non-polluted water body to
                            flourish. With frogs around the property, we are
                            quite sure the guests at the resort won’t be
                            complaining of mosquitoes or other insects!
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>

                  <v-flex align-self-center xs12 pa-4>
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm10 align-self-center
                        ><a
                          target="_blank"
                          :href="require('./../../assets/images/sami.png')"
                        >
                          <v-img
                            contain
                            :src="require('./../../assets/images/sami.png')"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#005f32"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 sm6 align-self-center pa-6>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 sm11 align-self-center pa-2 py-6>
                <v-layout wrap>
                  <v-flex xs12 px-2 py-1 align-self-center>
                    <span
                      class="poppinssemibold"
                      style="font-size:26px;color:#005f32"
                    >
                      3<span style="font-size:12px; ">RD</span> PLACE WINNER
                    </span>
                  </v-flex>
                  <v-flex align-self-center xs12 px-2 py-1>
                    <span
                      class="poppinssemibold"
                      style="font-size:23px;color:#000"
                    >
                      Chinmayi Balaji
                    </span>
                  </v-flex>
                  <v-flex align-self-center xs12 px-2 py-1>
                    <v-card tile outlined>
                      <v-layout wrap pa-4>
                        <v-flex xs12 align-self-center pa-2>
                          <i>
                            <span
                              class="poppinssemibold"
                              style="font-size:18px;color:#000"
                            >
                              “This is a beautiful pair of the Malbar gliding
                              frog mock mating right before the heavy monsoon
                              showers in Agumbe.”
                            </span>
                          </i>
                        </v-flex>
                        <v-flex xs12 align-self-center pa-2 py-4>
                          <span
                            class="poppinsregular"
                            style="font-size:18px;color:#000"
                          >
                            <b>Another amazing click :</b> A Malabar gliding
                            frog in its habitat is a unique sight. And a pair of
                            them certainly makes one appreciate a bit more!
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>

                  <v-flex align-self-center xs12 pa-4>
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm10 align-self-center>
                        <a
                          target="_blank"
                          :href="require('./../../assets/images/chin.png')"
                        >
                          <v-img
                            contain
                            :src="require('./../../assets/images/chin.png')"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="#005f32"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 sm6 align-self-center pa-6>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12 md12 align-self-center pa-2 py-6>
                <v-layout wrap>
                  <v-flex xs12 px-2 py-6 align-self-center>
                    <span
                      class="poppinssemibold"
                      style="font-size:26px;color:#005f32"
                    >
                      THE SPECIAL MENTIONS
                    </span>
                  </v-flex>
                  <v-flex align-self-center xs12 px-2 py-1 text-left>
                    <span
                      class="poppinsregular"
                      style="font-size:18px;color:#000"
                    >
                      Three amazing frog pictures are awarded as Special
                      Mentions
                    </span>
                  </v-flex>

                  <v-flex align-self-center xs12 sm11 md12 pa-2 text-left>
                    <span
                      class="poppinssemibold"
                      style="font-size:20px;color:#000"
                    >
                      <li>Roshan Dhotre</li>
                      <li>Soumya Ranjan Bhattacharaya</li>
                      <li>Dhrishya K</li>
                    </span>
                  </v-flex>
                  <v-flex align-self-center xs12 px-2 py-1>
                    <v-card tile outlined>
                      <v-layout wrap>
                        <v-flex xs12 sm6 md4 pa-2 align-self-start>
                          <v-layout wrap pa-4>
                            <v-flex xs12 align-self-center pa-2>
                              <a
                                target="_blank"
                                :href="
                                  require('./../../assets/images/roshan.png')
                                "
                              >
                                <v-img
                                  contain
                                  :src="
                                    require('./../../assets/images/roshan.png')
                                  "
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="#005f32"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </a>
                            </v-flex>
                            <v-flex xs12 align-self-center text-left pa-2>
                              <span
                                class="poppinssemibold"
                                style="font-size:18px;color:#000"
                              >
                                Photo by Roshan Dhotre
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex xs12 sm6 md4 pa-2 align-self-start>
                          <v-layout wrap pa-4>
                            <v-flex xs12 align-self-center pa-2>
                              <a
                                target="_blank"
                                :href="
                                  require('./../../assets/images/soumya.png')
                                "
                              >
                                <v-img
                                  contain
                                  :src="
                                    require('./../../assets/images/soumya.png')
                                  "
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="#005f32"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </a>
                            </v-flex>
                            <v-flex xs12 align-self-center text-left pa-2>
                              <span
                                class="poppinssemibold"
                                style="font-size:18px;color:#000"
                              >
                                Photo by Soumya Ranjan Bhattacharaya
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex xs12 sm6 md4 pa-2 align-self-start>
                          <v-layout wrap pa-4>
                            <v-flex xs12 align-self-center pa-2>
                              <a
                                target="_blank"
                                :href="
                                  require('./../../assets/images/drisya.png')
                                "
                              >
                                <v-img
                                  contain
                                  :src="
                                    require('./../../assets/images/drisya.png')
                                  "
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="#005f32"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </a>
                            </v-flex>
                            <v-flex xs12 align-self-center text-left pa-2>
                              <span
                                class="poppinssemibold"
                                style="font-size:18px;color:#000"
                              >
                                Photo by Dhrishya K
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 sm12 align-self-center pa-2 py-6>
                <v-layout wrap>
                  <v-flex xs12 px-2 py-6 align-self-center>
                    <span
                      class="poppinssemibold"
                      style="font-size:26px;color:#005f32"
                    >
                      LUCKY WINNERS FOR THE FROG QUIZ SWEEPSTAKE
                    </span>
                  </v-flex>

                  <v-flex align-self-center xs12 pa-2 text-center>
                    <span
                      class="poppinssemibold"
                      style="font-size:22px;color:#000"
                    >
                      Amitayu Dey
                    </span>
                  </v-flex>

                  <v-flex align-self-center xs12 pa-2 text-center>
                    <span
                      class="poppinssemibold"
                      style="font-size:22px;color:#000"
                    >
                      Darshit Shah
                    </span>
                  </v-flex>

                  <v-flex align-self-center xs12 pa-2 text-center>
                    <span
                      class="poppinssemibold"
                      style="font-size:22px;color:#000"
                    >
                      Anjana Sinha
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: ["banner"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      rulesInfo: [],
      ruleselected: null,
      cardData: [
        {
          image: require("./../../assets/images/Fquiz.jpg"),
          desc:
            "Test your ‘Frog Wisdom’ and see how much you know about the frogs.  Three winners will be chosen at random and we will send the ‘surPrizes’!!",
        },
      ],
    };
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
</style>
