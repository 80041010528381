<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11 py-6>
        <v-layout wrap fill-height>
          <v-flex
            xs12
            align-self-center
            pa-4
            py-12 
          >
            <v-layout wrap fill-height justify-center>
              <v-flex xs12 md6 align-self-center pa-1>
                <v-card tile flat class="pa-2">
                  <span
                    style="font-size:18px;color:#000;"
                    class=" poppinssemibold text-none"
                  >
                    Hey there! The contest ended on 22nd
                    of June.
                  </span>
                  <br />
                  <br />
                  <span
                    style="font-size:18px;color:#000;"
                    class=" poppinsregular text-none"
                  >
                    <span
                      class="poppinssemibold"
                      style="cursor:pointer;color:#005f32"
                      >Sign Up</span
                    >
                    for our newsletter to stay up to date about more such
                    exciting contests and giveways and to learn about WTI's
                    wildlife conservation initiatives.
                  </span>
                </v-card>
              </v-flex>
              <v-flex xs12 md6 align-self-center pa-1>
                <v-card tile class="pa-2 rounded-lg" color="#005f32">
                  <v-form ref="sform" v-model="svalid" lazy-validation>
                    <v-layout wrap pa-4>
                      <v-flex xs12 align-self-center px-2 pb-2>
                        <span
                          class="poppinsregular text-uppercase"
                          style="font-size:22px;color:#ffffff"
                        >
                          Sign Up for our newsletter
                        </span>
                      </v-flex>
                      <v-flex xs12 sm6 align-self-center pa-2>
                        <v-text-field
                          class="vtexty"
                          color="#ffffff"
                          :rules="reqRules"
                          hide-details
                          v-model="sfirstName"
                          label="First name"
                          dense
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 align-self-center pa-2>
                        <v-text-field
                          class="vtexty"
                          color="#ffffff"
                          :rules="reqRules"
                          v-model="slastName"
                          hide-details
                          label="Last name"
                          dense
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm8 align-self-center pa-2>
                        <v-text-field
                          class="vtexty"
                          color="#ffffff"
                          v-model="semail"
                          :rules="emailRules"
                          hide-details
                          label="Email"
                          dense
                        ></v-text-field>
                      </v-flex>

                      <v-flex xs12 sm4 align-self-center pa-2 text-right>
                        <v-btn
                          dark
                          large
                          block
                          class="rounded-lg"
                          color="#ffffff"
                          v-if="svalid"
                          @click="subscribeNow()"
                        >
                          <span class="poppinssemibold" style="color:#005f32"
                            >Subscribe</span
                          >
                        </v-btn>
                        <v-btn
                          dark
                          large
                          block
                          class="rounded-lg"
                          color="#ffffff"
                          v-else
                          @click="sValidate()"
                        >
                          <span class="poppinssemibold" style="color:#005f32"
                            >Subscribe</span
                          >
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["idd", "notThis"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      rulesInfo: [],
      ruleselected: null,
      reqRules: [(v) => !!v || "This field is required*"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      valid: true,
      svalid: true,
      themeList: [
        {
          name: "Click a frog",
        },
        // {
        //   name: "Paint a frog",
        // },
        // {
        //   name: "Be a frog",
        // },
      ],
      contact: {
        firstName: "",
        lastName: "",
        issolemnly: true,
        ismonthly: true,
        selectTheme: null,
        description: "",
        email: "",
      },
      formDataCv: new FormData(),
      cvFile: null,
      semail: "",
      sfirstName: "",
      slastName: "",
    };
  },
  beforeMount() {
    this.contact.selectTheme = this.themeList[0];
  },
  watch: {
    idd() {
      if (this.idd && this.notThis == 10) {
        this.scroller(this.idd);
      }
    },
  },
  methods: {
    sValidate() {
      if (!this.sfirstName) {
        this.msg = "Firstname is required*";
        this.showSnackBar = true;
        return;
      } else if (!this.slastName) {
        this.msg = "Lastname is required*";
        this.showSnackBar = true;
        return;
      } else if (!this.semail) {
        this.msg = "Email is required*";
        this.showSnackBar = true;
        return;
      }
    },
    subscribeNow() {
      this.sfirstName = this.sfirstName.trim();
      this.slastName = this.slastName.trim();
      this.semail = this.semail.trim();
      this.appLoading = false;
      if (!this.sfirstName) {
        this.msg = "Firstname is required*";
        this.showSnackBar = true;
        return;
      } else if (!this.slastName) {
        this.msg = "Lastname is required*";
        this.showSnackBar = true;
        return;
      } else if (!this.semail) {
        this.msg = "Email is required*";
        this.showSnackBar = true;
        return;
      } else {
        var data = {};
        data["firstName"] = this.sfirstName;
        data["lastName"] = this.slastName;
        data["email"] = this.semail;
        axios({
          method: "POST",
          url: "/newsletter/add",
          data: data,
        })
          .then((response) => {
            if (response.data.status) {
              this.appLoading = false;
              // this.msg="Thank you!"
              // this.showSnackBar=true
              this.$refs.sform.resetValidation();
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
            this.clearOff();
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;

            console.log(err);
          });
      }
    },
    scroller(id) {
      id = "prizeCol";
      console.log("priz id called=", id);
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
      this.idd = null;
    },
    clickedOn() {
      // this.$emit("stepper", {
      //   isClicked: true,
      // });
      document.getElementById("ruleId").scrollIntoView({
        behavior: "smooth",
      });
    },
    browseImage(event) {
      var got = event.target.files[0];

      if (got.type == "application/pdf") {
        this.cvFile = got;
      } else {
        var img;
        img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        var ty = event.target.files[0];
        console.log(ty);
        if (ty.size > 3145728) {
          this.msg = "File size Should be less than 3MB";
          this.showSnackBar = true;
          return;
        } else {
          this.cvFile = got;

          // img.onload = () => {
          //   this.cvFile = URL.createObjectURL(event.target.files[0]);
          // };
          // console.log(img);
        }
      }
    },
    validate() {
      if (
        this.$refs.form.validate() &&
        this.contact.issolemnly &&
        this.cvFile
      ) {
        this.addApplication();
        // else {
        //   this.msg = "Please upload CV";
        //   this.showSnackBar = true;
        // }
      } else if (!this.cvFile) {
        this.msg = "Please upload image!";
        this.showSnackBar = true;
      } else {
        this.msg = "Please check all options!";
        this.showSnackBar = true;
      }
    },

    addApplication() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/quiz/formdata/add",
        data: this.contact,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;

            if (response.data.data._id) {
              if (this.cvFile) {
                this.uploadCV(response.data.data._id);
              }
            }
            this.clearOff();
            // Object.keys(this.contact).forEach(
            //   (key) => (this.contact[key] = '')
            // );
            this.flag = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;

          console.log(err);
        });
    },

    uploadCV(item) {
      this.appLoading = true;
      this.formDataCv.append("id", item);
      this.formDataCv.append("photo", this.cvFile);
      axios({
        method: "POST",
        url: "/quiz/upload/photo",
        data: this.formDataCv,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.showSnackBar = true;
            this.formDataCv = new FormData();
            this.cvFile = null;
            this.$refs.form.resetValidation();
            // this.$router.go();
          } else {
            this.formDataCv = new FormData();
            this.msg = "Can't Upload CV.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Uncaught!Can't Upload CV.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    clearOff() {
      this.contact.firstName = "";
      this.contact.lastName = "";

      this.contact.issolemnly = true;
      this.contact.ismonthly = true;
      this.contact.selectTheme = null;
      this.contact.description = "";
      this.contact.email = "";
      this.slastName = "";
      this.sfirstName = "";
      this.semail = "";
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
</style>
