<template>
  <div v-if="banner">
    
    <MainBanner />
    <div v-if="flag">
      <Banner
        :banner="banner"
        :doScroll="doScroll"
        @bstepper="winStepper"
        @stepperPrize="winStepperPrize"
      />
    </div>
    <div id="subform">
      <SubForm @stepper="winStepper2" :idd="id" :notThis="notThis" />
    </div>
    <!-- <Quiz /> -->
  </div>
</template>
<script>
import MainBanner from "./mainBanner";
import Banner from "./Banner";
import SubForm from "./SubForm";
// import Quiz from "./Quiz";
import axios from "axios";
export default {
  data() {
    return {
      isActive: false,
      appLoading: false,
      showSnackBar: false,
      timeOut: 5000,
      msg: null,
      gallery: [],
      banner: [],
      doScroll: false,
      flag: true,
      id: null,
      notThis: 5,
    };
  },
  components: {
    MainBanner,
    Banner,
    SubForm,
    // Quiz,
  }, 
  beforeMount() {
    // this.getData();
    

  },
  methods: {
    scroll(id) {
      id='subform'
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    winStepperPrize(window_data) {
      if (window_data.isClickedPrize) {
        // alert("hi")
        console.log("hi Prized");
        this.id = null;
        this.id = "prizeCol";
        this.notThis = 10;
        // this.scroll("prizeCol");
      }
    },
    winStepper(window_data) {
      if (window_data.isClicked) {
        this.scroll("subform");
      }
    },
    winStepper2(window_data) {
      this.doScroll = false;
      if (window_data.isClicked) {
        this.flag = false;
        this.doScroll = true;
        this.notThis = 6;

        this.flag = true;
        console.log("init rules");
      }
    },

    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/home/get",
      })
        .then((response) => {
          if (response.data.status) {
            this.banner = response.data.data.top;
            this.gallery = response.data.data.gallery.data;
            // this.knowus=this.knownList[0]._id
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
