<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout
      wrap
      justify-center
      :style="
        $vuetify.breakpoint.name == 'xs'
          ? 'margin-top: 50px'
          : $vuetify.breakpoint.name == 'sm'
          ? 'margin-top: 48px'
          : 'margin-top: 67px'
      "
      class="adjustTop"
    >
      <v-flex xs12 text-left>
        <v-img
          class="align-top"
          :contain="$vuetify.breakpoint.name == 'xs' ? true : true"
          :cover="$vuetify.breakpoint.name != 'xs' ? true : true"
          :height="$vuetify.breakpoint.name == 'xs' ? 'auto' : 'auto'"
          :src="require('./../../assets/images/JobsHeader.jpg')"
        >
          <!-- <v-card tile style="background-color:rgba(0, 0, 0, 0.6)">
            <v-layout wrap justify-center>
              <v-flex
                xs12
                align-self-center
                text-center
                pa-4
                px-6
                pt-16
              >
                <span
                  :style="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                      ? 'font-size:38px'
                      : 'font-size:75px'
                  "
                  style="color:#ffffff;text-shadow: 2px 2px #000;"
                  class="oswaldbold"
                >
                  Welcome to the #FrogsForPlanet!
                </span>
              </v-flex>
            </v-layout>
          </v-card> -->
        </v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
    };
  },
};
</script>
<style scoped>
@media only screen and (min-device-width: 375px) and (max-device-width: 424px) {
  .adjustTop {
    margin-top: 25px !important;
  }
}

  @media only screen and (min-device-width: 425px) and (max-device-width: 704px) {
  .adjustTop {
    margin-top: 34px !important;
  }
  }

</style>
